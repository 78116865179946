import IconButton from '@mui/material/IconButton'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import React, { useCallback } from 'react'
import { useRouter } from 'next/router'
import { PagePathConstant } from '@lib/constants'

function AddTabAction() {
  const router = useRouter()

  const handleAddTabClick = useCallback(
    () => router.push(PagePathConstant.MANAGEMENT_VIEW),
    []
  )
  return (
    <IconButton
      onClick={handleAddTabClick}
      color="inherit"
      data-testid="add-dashboard-view-btn"
    >
      <AddRoundedIcon />
    </IconButton>
  )
}

export default AddTabAction
