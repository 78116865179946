import { useRouter } from 'next/router'
import React, { useCallback } from 'react'
import { PagePathConstant } from '@lib/constants'
import { StyledViewAllButton } from '@features/DashboardV4/entropy-trends/styles'

function ViewAllButton() {
  const router = useRouter()

  const handleClick = useCallback(
    () => router.push(PagePathConstant.ENTROPY_TRENDS),
    []
  )

  return (
    <StyledViewAllButton onClick={handleClick}>View All</StyledViewAllButton>
  )
}

export { ViewAllButton }
