/* eslint-disable import/no-extraneous-dependencies */
import { SystemHelper } from '@lib/helpers'
import { useEffect, useState } from 'react'
import { AwsFsChecksStatsResponse } from '@lib/clients/aws-statistics/types'
import { AwsStatsClient } from '@lib/clients/aws-statistics'
import { useSelector } from 'react-redux'
import { getLiveAssetsCriteria } from '@store/selectors/list-live-assets.selector'

const INITIAL_FS_CHECKS_COUNT: AwsFsChecksStatsResponse = {
  corruptedFilesystems: 0,
  healthyFilesystems: 0,
}

const awsStatsClient = new AwsStatsClient()

export function useFetchAwsFsChecksStats() {
  const { accountIds, regions, includeTags, excludeTags } = useSelector(
    getLiveAssetsCriteria
  )

  const [isLoading, setIsLoading] = useState(false)
  const [fsChecksCount, setFsChecksCount] = useState<AwsFsChecksStatsResponse>(
    INITIAL_FS_CHECKS_COUNT
  )

  useEffect(() => {
    let isMounted = true

    const fetchFsChecksStats = async () => {
      try {
        setIsLoading(true)

        const response = await awsStatsClient.getFsChecksStats({
          regions,
          accountIds,
          includeTags,
          excludeTags,
        })

        if (isMounted) {
          setFsChecksCount(response)
        }
      } catch (error) {
        SystemHelper.sendSentryIfProd(
          'awsStatsClient.getFsChecksStats: failed to fetch file fs checks count'
        )
      } finally {
        setIsLoading(false)
      }
    }

    fetchFsChecksStats()

    return () => {
      isMounted = false
      setFsChecksCount(INITIAL_FS_CHECKS_COUNT)
      setIsLoading(false)
    }
  }, [accountIds, regions, includeTags?.tags, excludeTags?.tags])

  return {
    isLoading,
    ...fsChecksCount,
  }
}
