/* eslint-disable import/no-extraneous-dependencies */
import Box from '@mui/material/Box'
import { useRouter } from 'next/router'
import { WidgetHead } from '@features/DashboardV4/widgets-with-scale/widget-head'
import type { TableElementEvent } from '@features/DashboardV4/widgets-with-scale/shared'
import styles from './rpo.module.css'
import {
  INVENTORY_INSTANCES_SHOW_RPO_ASC,
  isAssetOutOfSchedule,
} from './utilities'
import { RetentionCommonStatistic } from './common-statistic'
import { Scale } from '@features/DashboardV4/widgets-with-scale/shared'
import { useSelector } from 'react-redux'
import {
  getBackups,
  getIsLiveAssetsLoading,
  getLiveAssets,
} from '@store/selectors/list-live-assets.selector'
import { usePoliciesList } from './use-policies-list'
import { AwsRpoStats } from '@lib/clients/aws-statistics/types'

interface Props {
  isLoading: boolean
  rpoStats: AwsRpoStats
}

function RpoStatsWgt({ isLoading, rpoStats }: Props) {
  const { lowestRpo, highestRpo, backupsOutOfSchedule, rpoStatsQuantilesList } =
    rpoStats
  const { ec2Instances, ebsVolumes } = useSelector(getLiveAssets)
  const isLiveAssetsLoading = useSelector(getIsLiveAssetsLoading)

  const { policiesList, isPoliciesListLoading } = usePoliciesList()

  const { lastBackups } = useSelector(getBackups)

  const { push } = useRouter()

  const instancesNumber = ec2Instances.length
  const volumesNumber = ebsVolumes.length
  const instancesOutOfScheduleNumber = ec2Instances.filter((instance) =>
    isAssetOutOfSchedule(instance, lastBackups, policiesList)
  ).length

  const volumesOutOfScheduleNumber = ebsVolumes.filter((volume) =>
    isAssetOutOfSchedule(volume, lastBackups, policiesList)
  ).length

  const handlerNavigateToDrillDownLink = (drillDownLink: string) => () => {
    push(drillDownLink)
  }

  const handleNavigateToDrillDownLink = (link: string) => {
    return (event: TableElementEvent) => {
      event.stopPropagation()
      push(link)
    }
  }

  const isDataLoading =
    isLoading || isLiveAssetsLoading || isPoliciesListLoading
  const shouldRenderScaleWidget = rpoStatsQuantilesList.length > 0

  return (
    <Box className={styles.root} data-testid="rpo-widget">
      <Box
        onClick={handlerNavigateToDrillDownLink(
          INVENTORY_INSTANCES_SHOW_RPO_ASC
        )}
      >
        <WidgetHead title="Recovery Point Objective (RPO)" />
      </Box>

      <Box className={styles.container}>
        <RetentionCommonStatistic
          isLoading={isDataLoading}
          backupsOutOfSchedule={backupsOutOfSchedule}
          lowestRpo={lowestRpo}
          highestRpo={highestRpo}
          rpoStatsQuantilesList={rpoStatsQuantilesList}
          onClick={handleNavigateToDrillDownLink}
          instancesNumber={instancesNumber}
          volumesNumber={volumesNumber}
          instancesOutOfScheduleNumber={instancesOutOfScheduleNumber}
          volumesOutOfScheduleNumber={volumesOutOfScheduleNumber}
        />
        <Box className={styles.wrapScale}>
          {shouldRenderScaleWidget && (
            <Scale isLoading={isLoading} quantiles={rpoStatsQuantilesList} />
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default RpoStatsWgt
