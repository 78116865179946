import { EntropyGraph } from '@features/common/entropy-graph'
import MenuItem from '@mui/material/MenuItem'
import { SelectChangeEvent } from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import React, { useEffect, useMemo, useState } from 'react'
import { WidgetHeader } from '../common'
import { StyledTimeSelect } from './styles'
import {
  EntropyTrendsTimeSelection,
  incrementEntropyTrendsTimeSelection,
} from '@features/DashboardV4/entropy-trends/types'
import {
  CHART_OPTIONS,
  columns,
  MENU_ITEMS,
} from '@features/DashboardV4/entropy-trends/consts'
import {
  buildTimeRangeForRequest,
  getDateFormatBasedByRange,
} from '@features/DashboardV4/entropy-trends/utils'
import { ViewAllButton } from '@features/DashboardV4/entropy-trends/view-all-button'
import { FetchEntropyTrendsHistoryParams } from '@features/asset-page/more-details/more-details-modal/entropy-trends-details/entropy-trends-details-store/types'
import { EntropyTrendsHistory } from '@lib/models/anomalies'
import { EntropyDetectionClient } from '@lib/clients/entropy-detection'
import { usePrepareFetchEntropyTrendsHistoryParams } from '@lib/hooks/entropy-trends/fetch-get-entropy-trends-history'

const ENTROPY_MESSAGE =
  // eslint-disable-next-line quotes
  "Detect potential internal threats by identifying newly encrypted or suspiciously modified files across your network. This behavioral model analyzes file changes between scans, flagging files that show signs of encryption, and includes file type analysis to help safeguard your company's data."

const client = new EntropyDetectionClient()

async function fetchInitialEntropyTrendsHistory(
  filters: FetchEntropyTrendsHistoryParams
): Promise<[EntropyTrendsHistory, FetchEntropyTrendsHistoryParams]> {
  const entropyTrendsHistoryResponse = await client.getEntropyTrendsHistory(
    filters
  )

  if (entropyTrendsHistoryResponse.totalAnomalyFindingsCount > 0) {
    return [entropyTrendsHistoryResponse, filters]
  }

  const incrementedTimeSelection = incrementEntropyTrendsTimeSelection(
    filters.timeSelection
  )

  if (incrementedTimeSelection === filters.timeSelection) {
    return [entropyTrendsHistoryResponse, filters]
  }

  const newTimeRange = buildTimeRangeForRequest(
    incrementedTimeSelection,
    filters.startAt,
    filters.endAt
  )

  return fetchInitialEntropyTrendsHistory({
    ...filters,
    timeSelection: incrementedTimeSelection,
    startAt: newTimeRange.startDate,
    endAt: newTimeRange.endDate,
    timeGranularity: newTimeRange.timeGranularity,
  })
}

async function fetchEntropyTrendsHistory(
  filters: FetchEntropyTrendsHistoryParams
) {
  return client.getEntropyTrendsHistory(filters)
}

function EntropyTrends() {
  const [entropyTrendsTimeSelection, setEntropyTrendsTimeSelection] =
    useState<EntropyTrendsTimeSelection>(EntropyTrendsTimeSelection.TODAY)
  const [entropyTrendsHistory, setEntropyTrendsHistory] =
    useState<EntropyTrendsHistory>(new EntropyTrendsHistory([]))
  const [isLoading, setIsLoading] = useState(false)

  const timeRangeForRequest = buildTimeRangeForRequest(
    entropyTrendsTimeSelection
  )

  const params = usePrepareFetchEntropyTrendsHistoryParams({
    timeGranularity: timeRangeForRequest.timeGranularity,
    startAt: timeRangeForRequest.startDate,
    endAt: timeRangeForRequest.endDate,
    assetIdsList: [],
    timeSelection: entropyTrendsTimeSelection,
  })

  const rows = entropyTrendsHistory.anomalyDataPoints.map((dataPoint) => [
    dataPoint.timestamp,
    dataPoint.anomalyFindingCount,
  ])

  const chartOptions = useMemo(() => {
    return {
      ...CHART_OPTIONS,
      hAxis: {
        type: 'string',
        format: getDateFormatBasedByRange(entropyTrendsTimeSelection),
      },
    }
  }, [entropyTrendsTimeSelection])

  // We set unknown because when we set custom styled Select types inheritance are broken
  const handleSelectDate = async (event: SelectChangeEvent<unknown>) => {
    setEntropyTrendsTimeSelection(
      event.target.value as EntropyTrendsTimeSelection
    )

    const newTimeRange = buildTimeRangeForRequest(
      event.target.value as EntropyTrendsTimeSelection,
      timeRangeForRequest.startDate,
      timeRangeForRequest.endDate
    )

    setIsLoading(true)

    const entropyTrendsHistoryResponse = await fetchEntropyTrendsHistory({
      assetIdsList: params.assetIdsList,
      assetsFilter: params.assetsFilter,
      timeSelection: event.target.value as EntropyTrendsTimeSelection,
      startAt: newTimeRange.startDate,
      endAt: newTimeRange.endDate,
      timeGranularity: newTimeRange.timeGranularity,
    })

    setIsLoading(false)

    setEntropyTrendsHistory(entropyTrendsHistoryResponse)
  }

  useEffect(() => {
    setIsLoading(true)
    fetchInitialEntropyTrendsHistory({
      assetIdsList: params.assetIdsList,
      assetsFilter: params.assetsFilter,
      timeSelection: entropyTrendsTimeSelection,
      startAt: timeRangeForRequest.startDate,
      endAt: timeRangeForRequest.endDate,
      timeGranularity: timeRangeForRequest.timeGranularity,
    })
      .then(([entropyTrendsHistoryResponse, newFilters]) => {
        setEntropyTrendsTimeSelection(newFilters.timeSelection)
        setEntropyTrendsHistory(entropyTrendsHistoryResponse)
      })
      .finally(() => {
        setIsLoading(false)
      })

    return () => {
      setEntropyTrendsTimeSelection(EntropyTrendsTimeSelection.TODAY)
      setEntropyTrendsHistory(new EntropyTrendsHistory([]))
    }
  }, [])

  return (
    <Stack>
      <WidgetHeader title="Entropy Trends" hintMessage={ENTROPY_MESSAGE} />

      <Stack
        alignItems="center"
        direction="row"
        justifyContent="flex-end"
        paddingX="16px"
        gap="8px"
      >
        <StyledTimeSelect
          size="small"
          value={entropyTrendsTimeSelection}
          onChange={handleSelectDate}
        >
          {MENU_ITEMS.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </StyledTimeSelect>
        <ViewAllButton />
      </Stack>
      <EntropyGraph
        key={entropyTrendsTimeSelection}
        isLoading={isLoading}
        columns={columns}
        rows={rows}
        options={chartOptions}
      />
    </Stack>
  )
}

export default EntropyTrends
