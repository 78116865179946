import React from 'react'
import SystemHelper from '@lib/helpers/system.helper'
import PagePathConstant from '@lib/constants/page-path.constant'
import LayoutConstant from '@lib/constants/layout.constant'
import DashboardV4 from '@features/DashboardV4/DashboardV4'

function DashboardPage() {
  return <DashboardV4 />
}

DashboardPage.pageMeta = {
  metaTitle: SystemHelper.getCurrentPageName('Dashboard'),
  metaDescription: 'Main Dashboard Page',
  metaKeywords: '',
  path: PagePathConstant.DASHBOARD,
  layout: LayoutConstant.MainTopBar,
  title: 'Data Resilience Control Center',
}

export default DashboardPage
