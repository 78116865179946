import React, { memo } from 'react'
import { Typography } from '@mui/material'
import { getIconByRisk } from './icons'
import { RiskBlockContainer, StyledRiskBlock } from './styles'
import { renderStatusRisk } from './define-status-risk'
import { Risk } from './types'

interface Props {
  variant: Risk[keyof Risk]
  count: number
  onClick?: () => void
}

function RiskBlock({ variant, count, onClick }: Props) {
  return (
    <RiskBlockContainer clickable={Boolean(onClick)} onClick={onClick}>
      {getIconByRisk(variant)}
      <StyledRiskBlock>
        <Typography
          data-testid="risk-count"
          lineHeight="22px"
          variant="caption"
          fontSize="22px"
          fontWeight="600"
        >
          {count}
        </Typography>
        <Typography
          variant="caption"
          data-testid="risk-variant"
          fontSize="14px"
          fontWeight="400"
        >
          {renderStatusRisk(variant)}
        </Typography>
      </StyledRiskBlock>
    </RiskBlockContainer>
  )
}

export default memo(RiskBlock)
