import { PagePathConstant, UserSettings } from '@lib/constants'
import Modal from '@lib/constants/modal.constant'
import { StrHelper } from '@lib/helpers'
import { useModal } from '@lib/hooks/useModal'
import SettingsService from '@lib/services/high/settings.service'
import { setSlice } from '@store/actions/list-live-assets.action'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { DEFAULT_VIEWS, TabViewInterface } from '.'
import { getViewsFromConfig } from '..'
import { ViewKind } from '@features/DashboardV4/management-view/types'
import { useOvaDashboardStoreActions } from '@features/ova-dashboard/ova-dashboard-store/ova-dashboard-store'
import {
  useGetTemporaryTab,
  useTabsStoreActions,
} from '@features/DashboardV4/management-view/tabs-store'
import { isOVASliceCriteria } from '@features/DashboardV4/management-view/slice-criteria-guards'

const INITIAL_ACTIVE_VIEW = 0

function useManagementViewConfig() {
  const router = useRouter()

  const views = getViewsFromConfig()

  const actions = useOvaDashboardStoreActions()

  const { resetTab } = useTabsStoreActions()

  const dispatch = useDispatch()

  const [viewList, setViewList] = useState<Array<TabViewInterface>>([...views])

  const temporaryTab = useGetTemporaryTab()

  const viewsToDisplay = temporaryTab
    ? [...DEFAULT_VIEWS, ...getViewsFromConfig(), temporaryTab]
    : [...DEFAULT_VIEWS, ...getViewsFromConfig()]

  const [activeView, setActiveView] = useState<number>(INITIAL_ACTIVE_VIEW)

  const handleViewUpdateTemporaryTab = () => {
    SettingsService.updateSetting(UserSettings.TabViews, [
      ...views,
      temporaryTab,
    ])

    resetTab()
  }

  const handleViewUpdate = (id: string) => {
    const isTemporaryTab = temporaryTab?.id === id

    if (isTemporaryTab) {
      handleViewUpdateTemporaryTab()
      return
    }

    const viewIndex = viewsToDisplay.findIndex((v) => v.id === id)

    if (viewIndex === -1) {
      return
    }

    const indexEncoded = StrHelper.base64Encode(viewIndex)

    router.push(`${PagePathConstant.MANAGEMENT_VIEW_EDIT}?t=${indexEncoded}`)
  }

  const { openModal, modalProps } = useModal<{ id: string; name: string }>(
    Modal.deleteTabView,
    ({ id }) => {
      const currentTabsListWithoutRemoved = viewList.filter(
        (tab) => tab.id !== id
      )

      setViewList(currentTabsListWithoutRemoved)

      setActiveView(INITIAL_ACTIVE_VIEW)

      router.replace(`${router.pathname}?t=${INITIAL_ACTIVE_VIEW}`, undefined, {
        shallow: true,
      })

      SettingsService.updateSetting(
        UserSettings.TabViews,
        currentTabsListWithoutRemoved
      )
    }
  )

  const handleViewDelete = (id: string) => {
    const tab = viewsToDisplay.find((view) => view.id === id)

    if (!tab) {
      return
    }

    const isTemporaryTab = temporaryTab?.id === id

    if (isTemporaryTab) {
      resetTab()
      setActiveView(INITIAL_ACTIVE_VIEW)

      router.replace(`${router.pathname}?t=${INITIAL_ACTIVE_VIEW}`, undefined, {
        shallow: true,
      })
      return
    }

    openModal({
      id: tab.id,
      name: tab.name,
    })
  }

  const handleSwitchView = (_event: any, index: number) => {
    const view = viewsToDisplay[index]

    if (!view) {
      return
    }

    router.replace(`${router.pathname}?t=${index}`, undefined, {
      shallow: true,
    })

    if (view.kind === ViewKind.AWS) {
      dispatch(setSlice(view.slice))
    } else {
      if (isOVASliceCriteria(view.slice)) {
        actions.setOvaAccountIds(view.slice?.accountIds || [])
        actions.setBackupProviders(view.slice?.backupVendors || [])
      }
    }

    setActiveView(index)
  }

  useEffect(() => {
    const { t } = router.query

    const index = Number(t)

    if (!t) {
      const defaultView =
        viewsToDisplay.find((view) => view.isDefaultDashboard) ||
        viewsToDisplay[0]

      if (!defaultView) {
        return
      }

      if (defaultView.kind === ViewKind.AWS) {
        dispatch(setSlice(defaultView.slice))
      } else {
        if (isOVASliceCriteria(defaultView.slice)) {
          actions.setOvaAccountIds(defaultView.slice?.accountIds || [])
          actions.setBackupProviders(defaultView.slice?.backupVendors || [])
        }
      }
      setActiveView(
        viewsToDisplay.findIndex((view) => view.id === defaultView.id)
      )
      return
    }

    const selectedView = viewsToDisplay[index]

    if (!selectedView) {
      return
    }

    if (selectedView.kind === ViewKind.AWS) {
      dispatch(setSlice(selectedView.slice))
    } else {
      if (isOVASliceCriteria(selectedView.slice)) {
        actions.setOvaAccountIds(selectedView.slice?.accountIds || [])
        actions.setBackupProviders(selectedView.slice?.backupVendors || [])
      }
    }

    setActiveView(index)
  }, [router.query.t, viewsToDisplay.length])

  useEffect(() => {
    const defaultViewIdx = viewsToDisplay.findIndex(
      (view) => view.isDefaultDashboard
    )

    if (router.query.t || defaultViewIdx === -1) {
      return
    }

    const selectedView = viewsToDisplay[defaultViewIdx]

    if (!selectedView) {
      return
    }

    setActiveView(defaultViewIdx)
  }, [router.query.t, viewsToDisplay.length])

  function isSelectedViewEditable(tabId: string) {
    return DEFAULT_VIEWS.findIndex((view) => view.id === tabId) === -1
  }

  return {
    viewsToDisplay,
    activeView,
    modalProps,
    handleViewUpdate,
    handleViewDelete,
    handleSwitchView,
    isSelectedViewEditable,
    isTemporaryTab: !!temporaryTab,
  }
}

export { useManagementViewConfig }
