/* eslint-disable import/no-extraneous-dependencies */
import React, { ReactNode } from 'react'
import styles from './threats.module.scss'
import { Box, Tooltip } from '@mui/material'
import RansomwareIcon from '@inline-img/general/ransomware-icon'
import MalwareIcon from '@inline-img/general/malware-icon'
import FsCheckIcon from '@inline-img/general/fs-check-icon'
import PagePathConstant from '@lib/constants/page-path.constant'
import { useRouter } from 'next/router'
import { Nullable } from '@lib/engine-types'
import LangHelper from '@lib/helpers/lang.helper'
import {
  ThreatSkeletonLoader,
  useFetchAwsThreat,
} from '@features/DashboardV4/threats-widgets'

function getRansomwareIcon(value: Nullable<number>): ReactNode {
  switch (value) {
    case null:
      return (
        <Tooltip title="Not Scanned for Ransomware" placement="top">
          <div className={`${styles.iconItem} ${styles.unscanned}`}>
            {RansomwareIcon({
              fill: '#999caa',
              width: '24',
              height: '24',
            })}
          </div>
        </Tooltip>
      )
    case 0:
      return (
        <Tooltip title="No Ransomware Found" placement="top">
          <div className={`${styles.iconItem} ${styles.clean}`}>
            {RansomwareIcon({
              fill: '#45aa5b',
              width: '24',
              height: '24',
            })}
          </div>
        </Tooltip>
      )
    default:
      return (
        <Tooltip title="Ransomware Found" placement="top">
          <div className={`${styles.iconItem} ${styles.infected}`}>
            {RansomwareIcon({
              fill: '#ff7878',
              width: '24',
              height: '24',
            })}
          </div>
        </Tooltip>
      )
  }
}

function getMalwareIcon(value: Nullable<number>): ReactNode {
  switch (value) {
    case null:
      return (
        <Tooltip title="Not Scanned for Malware" placement="top">
          <div className={`${styles.iconItem} ${styles.unscanned}`}>
            {MalwareIcon({
              fill: '#999caa',
              width: '24',
              height: '24',
            })}
          </div>
        </Tooltip>
      )
    case 0:
      return (
        <Tooltip title="No Malware Found" placement="top">
          <div className={`${styles.iconItem} ${styles.clean}`}>
            {MalwareIcon({
              fill: '#45aa5b',
              width: '24',
              height: '24',
            })}
          </div>
        </Tooltip>
      )
    default:
      return (
        <Tooltip title="Malware Found" placement="top">
          <div className={`${styles.iconItem} ${styles.infected}`}>
            {MalwareIcon({
              fill: '#ff7878',
              width: '24',
              height: '24',
            })}
          </div>
        </Tooltip>
      )
  }
}

function getFsCheckIcon(value: Nullable<number>): ReactNode {
  switch (value) {
    case null:
      return (
        <Tooltip title="Not scanned for File Corruption" placement="top">
          <div className={`${styles.iconItem} ${styles.unscanned}`}>
            {FsCheckIcon({
              fill: '#999caa',
              width: '24',
              height: '24',
            })}
          </div>
        </Tooltip>
      )
    case 0:
      return (
        <Tooltip title="No File Corruption Found" placement="top">
          <div className={`${styles.iconItem} ${styles.clean}`}>
            {FsCheckIcon({
              fill: '#45aa5b',
              width: '24',
              height: '24',
            })}
          </div>
        </Tooltip>
      )
    default:
      return (
        <Tooltip title="File Corruption Found" placement="top">
          <div className={`${styles.iconItem} ${styles.infected}`}>
            {FsCheckIcon({
              fill: '#ff7878',
              width: '24',
              height: '24',
            })}
          </div>
        </Tooltip>
      )
  }
}

function getColorForThreatsAmount(value: Nullable<number>) {
  switch (value) {
    case null:
      return `${styles.black}`
    case 0:
      return `${styles.green}`
    default:
      return `${styles.red}`
  }
}

function ThreatsWidget() {
  const router = useRouter()
  const {
    isLoading,
    ransomwareThreatsCount,
    malwareThreatsCount,
    corruptedFilesystemsCount,
    malwarePageToGo,
    corruptedFilesystemsPageToGo,
    ransomwarePageToGo,
  } = useFetchAwsThreat()

  if (isLoading) {
    return <ThreatSkeletonLoader title="Threats" />
  }

  const threatsDrillDownLink = `${PagePathConstant.ASSETS_WITH_THREATS}?t=1`

  const handleAllThreatsWidget = () => {
    router.push(threatsDrillDownLink)
  }

  const ransomwareDrillDownLink = `${PagePathConstant.ASSETS_WITH_THREATS}?t=${ransomwarePageToGo}&ransomware=all`

  const handleActiveRansomware = () => {
    router.push(ransomwareDrillDownLink)
  }

  const malwareDrillDownLink = `${PagePathConstant.ASSETS_WITH_THREATS}?t=${malwarePageToGo}&malware=all`

  const handleActiveMalware = () => {
    router.push(malwareDrillDownLink)
  }

  const fsCheckDrillDownLink = `${PagePathConstant.ASSETS_WITH_THREATS}?t=${corruptedFilesystemsPageToGo}&filesystem=all`

  const handleFileSystemCorruption = () => {
    router.push(fsCheckDrillDownLink)
  }

  return (
    <div className={styles.threatsComponent} data-testid="threats-widget">
      <div className={styles.titleWidget} onClick={handleAllThreatsWidget}>
        Threats
      </div>
      <div className={styles.threatsTable}>
        <div
          className={styles.threatsRow}
          data-testid="threats-widget-ransomware"
          onClick={handleActiveRansomware}
        >
          {getRansomwareIcon(ransomwareThreatsCount)}
          <Box
            className={`${styles.threatsAmount}
          ${getColorForThreatsAmount(ransomwareThreatsCount)}
          `}
            data-testid="ransomware-count"
          >
            {ransomwareThreatsCount}
          </Box>
          <Box className={`${styles.threatsDescription}`}>
            Active ransomware
          </Box>
        </div>
        <div
          className={styles.threatsRow}
          data-testid="threats-widget-malware"
          onClick={handleActiveMalware}
        >
          {getMalwareIcon(malwareThreatsCount)}
          <Box
            className={`${styles.threatsAmount}
          ${getColorForThreatsAmount(malwareThreatsCount)}
          `}
            data-testid="malware-count"
          >
            {malwareThreatsCount}
          </Box>
          <Box className={styles.threatsDescription}>Active malware</Box>
        </div>
        <div
          className={styles.threatsRow}
          data-testid="threats-widget-fs-check"
          onClick={handleFileSystemCorruption}
        >
          {getFsCheckIcon(corruptedFilesystemsCount)}
          <Box
            className={`${styles.threatsAmount}
        ${getColorForThreatsAmount(corruptedFilesystemsCount)}
        `}
          >
            {corruptedFilesystemsCount}
          </Box>
          <Box className={styles.threatsDescription}>
            {LangHelper.pluralizeEn(
              'File System corruption',
              corruptedFilesystemsCount
            )}
          </Box>
        </div>
      </div>
    </div>
  )
}

export default ThreatsWidget
