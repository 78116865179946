/* eslint-disable import/no-extraneous-dependencies */
import { CalculateBackupCoverageStats } from 'blues-corejs/dist'
import { useRouter } from 'next/router'
import { PagePathConstant } from '@lib/constants'
import { StatisticRowType } from '../widgets-with-scale/coverage-stats'
import { useSelector } from 'react-redux'
import { getTenantStatistics } from '@store/selectors/list-live-assets.selector'
import { StrHelper } from '@lib/helpers'
import NumHelper from '@lib/helpers/num.helper'
import { useFetchAwsBackupCoverage } from '@features/DashboardV4/widgets-with-scale/coverage-stats/use-fetch-aws-backup-coverage'
import { computeBackupCoverageStats } from '@features/DashboardV4/views/utils'

export const INVENTORY_INSTANCES_TABLE = PagePathConstant.EC2_INVENTORY_TABLE
export const INVENTORY_VOLUMES_TABLE = PagePathConstant.EBS_INVENTORY_TABLE

export function useBackupCoverageStats() {
  const tenantStatistics = useSelector(getTenantStatistics)
  const { isLoading, ec2, ebs } = useFetchAwsBackupCoverage()

  const router = useRouter()

  const {
    totalInstances,
    coveredInstances,
    totalVolumes,
    coveredVolumes,
    numberOfAssets,
    numberOfAssetsWithBackups,
    percentOfAssetsWithBackups,
  } = computeBackupCoverageStats({
    ec2,
    ebs,
  })

  const { storageReduction, storageSize } = new CalculateBackupCoverageStats({
    tenantStatistics,
  }).execute()

  const handleDrilldown = (url: string) => {
    router.push(url)
  }

  //TODO uncommemt and check this driildowns
  //after re-invent
  // const instancesNotCoveredByPolicy = ec2Instances.filter(
  //   ({ backupPolicies }) => backupPolicies.length === 0
  // ).length

  // const volumesNotCoveredByPolicy = ebsVolumes.filter(
  //   ({ backupPolicies }) => backupPolicies.length === 0
  // ).length

  // const numberOfInstancesNotCoveredByPolicy =
  //   numberOfInstances - instancesNotCoveredByPolicy
  // const numberOfVolumesNotCoveredByPolicy =
  //   numberOfVolumes - volumesNotCoveredByPolicy

  // const instancesDrillDown =
  //   numberOfInstancesNotCoveredByPolicy > 0
  //     ? INVENTORY_INSTANCES_TABLE
  //     : numberOfVolumesNotCoveredByPolicy > 0
  //     ? INVENTORY_VOLUMES_TABLE
  //     : INVENTORY_INSTANCES_TABLE

  // const volumesDrillDown =
  //   numberOfVolumesNotCoveredByPolicy > 0
  //     ? INVENTORY_VOLUMES_TABLE
  //     : INVENTORY_INSTANCES_TABLE

  const instancesDrillDown = INVENTORY_INSTANCES_TABLE

  const volumesDrillDown = INVENTORY_VOLUMES_TABLE

  const backupCoverageStats: Array<StatisticRowType> = [
    {
      assets: totalInstances,
      assetsWithBackups: coveredInstances,
      label: 'EC2 Instances',
      onRowClick: () => handleDrilldown(instancesDrillDown),
    },
    {
      assets: totalVolumes,
      assetsWithBackups: coveredVolumes,
      label: 'EBS Volumes',
      onRowClick: () => handleDrilldown(volumesDrillDown),
    },
  ]

  const [optimizedBackupSize, optimizedBackupSizeLabel] =
    StrHelper.realFileSize(storageSize, '0').split(' ')

  const optimizedSizeLabel = optimizedBackupSizeLabel ?? ''

  const optimizedSizeInfo = {
    value: `${String(optimizedBackupSize)} ${optimizedSizeLabel}`,
    label: 'Optimized size',
  }

  const storageReductionInfo = {
    value: `${NumHelper.roundTo(storageReduction, 2)}%`,
    label: 'Storage reduction',
  }

  return {
    isLoading,
    backupCoverageStats,
    numberOfAssets,
    numberOfAssetsWithBackups,
    percentOfAssetsWithBackups,
    optimizedSizeInfo,
    storageReductionInfo,
  }
}
