import React, { memo } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { StyledThreatRow } from '../styles'
import { INITIAL_TOTAL_AMOUNT } from '@features/DashboardV4/threats-widgets/threat-exposure/define-threat-rows'

interface GetAssetsCountProps {
  amount: number | string
  totalAmount: number | string
}

function GetAssetsCount({ totalAmount, amount }: GetAssetsCountProps) {
  if (totalAmount > INITIAL_TOTAL_AMOUNT) {
    return (
      <Grid item xs={5} alignContent="flex-end" sx={{ flexBasis: '0%' }}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          whiteSpace="nowrap"
        >
          <Typography
            variant="caption"
            fontSize="22px"
            fontWeight="600"
            marginRight="4px"
          >
            {amount}
          </Typography>

          <Typography variant="caption" fontSize="16px" fontWeight="600">
            / {totalAmount}
          </Typography>
        </Box>
      </Grid>
    )
  }

  return (
    <Typography variant="caption" fontWeight="600" fontSize="22px">
      {amount}
    </Typography>
  )
}

interface Props {
  icon: React.ReactNode
  amount: number | string
  totalAmount?: number | string
  description: string
}

function ThreatRow({ icon, amount, totalAmount = '', description }: Props) {
  return (
    <StyledThreatRow data-testid="threat-exposure-widget-row">
      {icon}
      <GetAssetsCount amount={amount} totalAmount={totalAmount} />
      <Typography variant="caption" fontSize="16px">
        {description}
      </Typography>
    </StyledThreatRow>
  )
}

export default memo(ThreatRow)
