/* eslint-disable import/no-extraneous-dependencies */
import React, { memo, useCallback } from 'react'
import styles from './ConfigurationVulnerabilities.module.css'
import {
  RiskBlock,
  TopConfigurationVulnerabilities,
  RiskSkeletonLoader,
} from './components'
import { useRouter } from 'next/router'
import PagePathConstant from '@lib/constants/page-path.constant'
import { RiskLevel } from 'blues-corejs/dist'
import { useManagementView, WidgetHeader } from '../common'
import clsx from 'clsx'
import Box from '@mui/material/Box'
import { useFetchAwsStorageMisconfigurations } from '@features/DashboardV4/ConfigurationVulnerabilities'
import { calculateTotalVulnerabilitiesByRisk } from '@features/DashboardV4/ConfigurationVulnerabilities/utils'
import { VulnerabilitiesTabs } from '@components-complex/dashboard-pages-v3/assets-with-vulnerabilities/useAssetsWithVulnerabilitiesData'
import { useIsEntropyTrendsEnabled } from '@lib/hooks/entropy-trends'

function RisksBlock({
  low,
  medium,
  high,
  isLoading,
}: {
  low: number
  medium: number
  high: number
  isLoading: boolean
}) {
  const router = useRouter()
  const handleClick = (risk: RiskLevel) => {
    router.push(
      `${PagePathConstant.ASSETS_WITH_VULNERABILITIES}?t=1&risk=${risk}`
    )
  }

  if (isLoading) {
    return <RiskSkeletonLoader />
  }

  return (
    <React.Fragment>
      <RiskBlock
        count={high}
        variant="high"
        onClick={() => handleClick(RiskLevel.High)}
      />
      <RiskBlock
        count={medium}
        variant="medium"
        onClick={() => handleClick(RiskLevel.Medium)}
      />
      <RiskBlock
        count={low}
        variant="low"
        onClick={() => handleClick(RiskLevel.Low)}
      />
    </React.Fragment>
  )
}

const RISK_BLOCK_TITLE = 'Storage Misconfigurations'

const TABLE_TITLE = 'Top Storage Misconfigurations'

function ConfigurationVulnerabilities() {
  const { push } = useRouter()
  const { isLoading, volumeVulnerabilitiesList, snapshotVulnerabilitiesList } =
    useFetchAwsStorageMisconfigurations()
  const totalRisks = calculateTotalVulnerabilitiesByRisk({
    volumeVulnerabilitiesList,
    snapshotVulnerabilitiesList,
  })

  const handleClick = useCallback(() => {
    push(
      `${PagePathConstant.ASSETS_WITH_VULNERABILITIES}?t=${VulnerabilitiesTabs.VOLUMES}`
    )
  }, [push])

  const { isSecurityView } = useManagementView()
  const { isEntropyEnabled } = useIsEntropyTrendsEnabled()

  // TODO: implement
  const shouldRenderWidgetWithRowDirection = isSecurityView || isEntropyEnabled

  return (
    <Box
      className={clsx(styles.container, {
        [String(styles.security)]: shouldRenderWidgetWithRowDirection,
      })}
    >
      <Box>
        <WidgetHeader title={RISK_BLOCK_TITLE} onClick={handleClick} />
        <Box className={styles.vulnerabilities_risks}>
          <RisksBlock
            low={totalRisks.low}
            high={totalRisks.high}
            medium={totalRisks.medium}
            isLoading={isLoading}
          />
        </Box>
      </Box>

      <Box className={styles.top_configuration_vulnerabilities}>
        <WidgetHeader title={TABLE_TITLE} onClick={handleClick} />
        <TopConfigurationVulnerabilities
          isLoading={isLoading}
          snapshotVulnerabilitiesList={snapshotVulnerabilitiesList}
          volumeVulnerabilitiesList={volumeVulnerabilitiesList}
        />
      </Box>
    </Box>
  )
}

export default memo(ConfigurationVulnerabilities)
