import Box from '@mui/material/Box'

import styles from './widget-head.module.css'

export const WIDGET_HEAD_COMPONENT_NAME = 'WidgetHead'
function WidgetHead({ title }: { title: string }) {
  return (
    <Box component="h6" className={styles.root}>
      {title}
    </Box>
  )
}

export default WidgetHead
