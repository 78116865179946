/* eslint-disable import/no-extraneous-dependencies */
import { SystemHelper } from '@lib/helpers'
import { useEffect, useState } from 'react'
import { AwsThreatStatsResponse } from '@lib/clients/aws-statistics/types'
import { AwsStatsClient } from '@lib/clients/aws-statistics'
import { getLiveAssetsCriteria } from '@store/selectors/list-live-assets.selector'
import { useSelector } from 'react-redux'

const INITIAL_THREATS_COUNT: AwsThreatStatsResponse = {
  malwareThreatsCount: 0,
  ransomwareThreatsCount: 0,
  corruptedFilesystemsCount: 0,
  ransomwarePageToGo: 0,
  malwarePageToGo: 0,
  corruptedFilesystemsPageToGo: 0,
}

const awsStatsClient = new AwsStatsClient()

export function useFetchAwsThreat() {
  const { accountIds, regions, includeTags, excludeTags } = useSelector(
    getLiveAssetsCriteria
  )
  const [isLoading, setIsLoading] = useState(false)
  const [threatsCount, setThreatsCount] = useState<AwsThreatStatsResponse>(
    INITIAL_THREATS_COUNT
  )

  useEffect(() => {
    let isMounted = true
    const fetchThreatStats = async () => {
      try {
        setIsLoading(true)

        const response = await awsStatsClient.getThreatStats({
          regions,
          accountIds,
          includeTags,
          excludeTags,
        })

        if (isMounted) {
          setThreatsCount(response)
        }
      } catch (error) {
        SystemHelper.sendSentryIfProd(
          'awsStatsClient.getThreatStats: failed to fetch threats count'
        )
      } finally {
        setIsLoading(false)
      }
    }

    fetchThreatStats()

    return () => {
      isMounted = false
      setThreatsCount(INITIAL_THREATS_COUNT)
      setIsLoading(false)
    }
  }, [accountIds, regions, includeTags?.tags, excludeTags?.tags])

  return {
    isLoading,
    ransomwareThreatsCount: threatsCount.ransomwareThreatsCount,
    malwareThreatsCount: threatsCount.malwareThreatsCount,
    corruptedFilesystemsCount: threatsCount.corruptedFilesystemsCount,
    ransomwarePageToGo: threatsCount.ransomwarePageToGo,
    malwarePageToGo: threatsCount.malwarePageToGo,
    corruptedFilesystemsPageToGo: threatsCount.corruptedFilesystemsPageToGo,
  }
}
