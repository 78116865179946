/* eslint-disable import/no-extraneous-dependencies */
import { SystemHelper } from '@lib/helpers'
import { useEffect, useState } from 'react'
import { AwsThreatExposureStatsResponse } from '@lib/clients/aws-statistics/types'
import { AwsStatsClient } from '@lib/clients/aws-statistics'
import { useSelector } from 'react-redux'
import { getLiveAssetsCriteria } from '@store/selectors/list-live-assets.selector'

const INITIAL_THREATS_EXPOSURE_COUNT: AwsThreatExposureStatsResponse = {
  accountsWithThreatsCount: 0,
  assetsWithThreatsCount: 0,
  regionsWithThreatsCount: 0,
  totalAssetsCount: 0,
}

const awsStatsClient = new AwsStatsClient()

export function useFetchAwsThreatExposure() {
  const { accountIds, regions, includeTags, excludeTags } = useSelector(
    getLiveAssetsCriteria
  )

  const [isLoading, setIsLoading] = useState(false)
  const [threatsExposureCount, setThreatsExposureCount] =
    useState<AwsThreatExposureStatsResponse>(INITIAL_THREATS_EXPOSURE_COUNT)

  useEffect(() => {
    let isMounted = true

    const fetchThreatExposureStats = async () => {
      try {
        setIsLoading(true)

        const response = await awsStatsClient.getThreatExposureStats({
          regions,
          accountIds,
          includeTags,
          excludeTags,
        })

        if (isMounted) {
          setThreatsExposureCount(response)
        }
      } catch (error) {
        SystemHelper.sendSentryIfProd(
          'awsStatsClient.getThreatExposureStats: failed to fetch threats exposure count'
        )
      } finally {
        setIsLoading(false)
      }
    }

    fetchThreatExposureStats()

    return () => {
      isMounted = false
      setThreatsExposureCount(INITIAL_THREATS_EXPOSURE_COUNT)
      setIsLoading(false)
    }
  }, [accountIds, regions, includeTags?.tags, excludeTags?.tags])

  return {
    isLoading,
    accountsWithThreatsCount: threatsExposureCount.accountsWithThreatsCount,
    assetsWithThreatsCount: threatsExposureCount.assetsWithThreatsCount,
    regionsWithThreatsCount: threatsExposureCount.regionsWithThreatsCount,
    totalAssetsCount: threatsExposureCount.totalAssetsCount,
  }
}
