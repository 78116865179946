import { Box, Grid, Typography } from '@mui/material'
import React, { memo } from 'react'

export type StatisticRowType = {
  assets: number
  assetsWithBackups: number
  label: string
  onRowClick?: () => void
}

function StatisticRow({
  assets,
  assetsWithBackups,
  label,
  onRowClick,
}: StatisticRowType) {
  return (
    <Grid
      container
      onClick={onRowClick}
      alignItems="center"
      flexWrap="nowrap"
      data-testid="statistics-row"
    >
      <Grid item xs={5} alignContent="flex-end">
        <Box
          display="flex"
          alignItems="center"
          paddingRight="8px"
          justifyContent="flex-end"
          whiteSpace="nowrap"
        >
          <Typography
            variant="caption"
            fontSize="22px"
            fontWeight="600"
            marginRight="4px"
          >
            {assetsWithBackups}
          </Typography>

          <Typography variant="caption" fontSize="16px" fontWeight="600">
            / {assets}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={7}>
        <Typography variant="caption" fontSize="16px" whiteSpace="nowrap">
          {label}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default memo(StatisticRow)
