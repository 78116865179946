import { Box, Typography } from '@mui/material'
import React, { CSSProperties, memo } from 'react'
import { useSelector } from 'react-redux'
import { getIsTenantStatisticsLoading } from '@store/selectors/list-live-assets.selector'
import { ScaleInfoSkeletonLoader } from '@features/DashboardV4/widgets-with-scale/skeleton'

function InfoBox({
  mainText,
  subText,
  sx,
}: {
  mainText: string
  subText: string
  sx?: CSSProperties
}) {
  const isTenantStatisticsLoading = useSelector(getIsTenantStatisticsLoading)

  if (isTenantStatisticsLoading) {
    return <ScaleInfoSkeletonLoader />
  }
  return (
    <Box
      sx={sx}
      display="flex"
      flexDirection="column"
      width="50%"
      textAlign="center"
    >
      <Typography variant="caption" fontSize="22px" fontWeight="600">
        {mainText}
      </Typography>
      <Typography variant="caption" fontSize="16px">
        {subText}
      </Typography>
    </Box>
  )
}

export default memo(InfoBox)
