/* eslint-disable import/no-extraneous-dependencies */
import { PagePathConstant } from '@lib/constants'
import {
  EbsVulnerabilityKind,
  SnapshotVulnerabilitiesTypeName,
} from 'blues-corejs/dist/models'

export function renderVulnerabilityName(id: string): {
  name: string
  drillDownLink: string
} {
  switch (id) {
    case SnapshotVulnerabilitiesTypeName.PUBLIC_PERMISSIONS:
      return {
        name: SnapshotVulnerabilitiesTypeName.PUBLIC_PERMISSIONS,
        drillDownLink: `${PagePathConstant.ASSETS_WITH_VULNERABILITIES}?t=2&vulnerability=${SnapshotVulnerabilitiesTypeName.PUBLIC_PERMISSIONS}`,
      }
    case SnapshotVulnerabilitiesTypeName.UNENCRYPTED:
      return {
        name: SnapshotVulnerabilitiesTypeName.UNENCRYPTED,
        drillDownLink: `${PagePathConstant.ASSETS_WITH_VULNERABILITIES}?t=2&vulnerability=${SnapshotVulnerabilitiesTypeName.UNENCRYPTED}`,
      }
    case EbsVulnerabilityKind.NOT_USING_CMK:
      return {
        name: EbsVulnerabilityKind.NOT_USING_CMK,
        drillDownLink: `${PagePathConstant.ASSETS_WITH_VULNERABILITIES}?t=1&vulnerability=${EbsVulnerabilityKind.NOT_USING_CMK}`,
      }
    case EbsVulnerabilityKind.UNENCRYPTED:
      return {
        name: EbsVulnerabilityKind.UNENCRYPTED,
        drillDownLink: `${PagePathConstant.ASSETS_WITH_VULNERABILITIES}?t=1&vulnerability=${EbsVulnerabilityKind.UNENCRYPTED}`,
      }
    case EbsVulnerabilityKind.UNATTACHED:
      return {
        name: EbsVulnerabilityKind.UNATTACHED,
        drillDownLink: `${PagePathConstant.ASSETS_WITH_VULNERABILITIES}?t=1&vulnerability=${EbsVulnerabilityKind.UNATTACHED}`,
      }
    case EbsVulnerabilityKind.NOT_CYBER_SCANNED:
      return {
        name: EbsVulnerabilityKind.NOT_CYBER_SCANNED,
        drillDownLink: `${PagePathConstant.ASSETS_WITH_VULNERABILITIES}?t=1&vulnerability=${EbsVulnerabilityKind.NOT_CYBER_SCANNED}`,
      }

    case EbsVulnerabilityKind.SNAPSHOTS_OLDER_7DAYS:
      return {
        name: EbsVulnerabilityKind.SNAPSHOTS_OLDER_7DAYS,
        drillDownLink: `${PagePathConstant.ASSETS_WITH_VULNERABILITIES}?t=1&vulnerability=${EbsVulnerabilityKind.SNAPSHOTS_OLDER_7DAYS}`,
      }

    case EbsVulnerabilityKind.NO_BACKUPS:
      return {
        name: EbsVulnerabilityKind.NO_BACKUPS,
        drillDownLink: `${PagePathConstant.ASSETS_WITH_VULNERABILITIES}?t=1&vulnerability=${EbsVulnerabilityKind.NO_BACKUPS}`,
      }

    default:
      return {
        name: id,
        drillDownLink: `${PagePathConstant.ASSETS_WITH_VULNERABILITIES}?t=1`,
      }
  }
}
