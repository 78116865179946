import Divider from '@mui/material/Divider'

import styles from './common-statistic.module.css'
import {
  TableElementEvent,
  convertMillisecondsWithLabel,
} from '@features/DashboardV4/widgets-with-scale'
import {
  INVENTORY_INSTANCES_TABLE,
  INVENTORY_INSTANCES_TABLE_DESC,
  INVENTORY_VOLUMES_TABLE,
  INVENTORY_VOLUMES_TABLE_DESC,
} from '../consts'
import { RpoRetentionSkeletonLoader } from '@features/DashboardV4/widgets-with-scale/skeleton'
import { AwsRetentionStats } from '@lib/clients/aws-statistics/types'

type RetentionCommonStatisticOwnProps = {
  onClick: (link: string) => (event: TableElementEvent) => void
  isShowInstancesTable: boolean
  isLoading: boolean
} & Omit<AwsRetentionStats, 'quantiles'>

function RetentionCommonStatistic({
  averageRetention,
  topQuartile,
  bottomQuartile,
  onClick,
  isShowInstancesTable,
  isLoading,
}: RetentionCommonStatisticOwnProps) {
  const averageRetentionMilliseconds = averageRetention || 0
  const bottomQuartileMilliseconds = bottomQuartile || 0
  const topQuartileMilliseconds = topQuartile || 0

  const { label: averageRetentionLabel, value: averageRetentionValue } =
    convertMillisecondsWithLabel(averageRetentionMilliseconds)
  const {
    label: retentionBottomQuartileLabel,
    value: retentionBottomQuartileValue,
  } = convertMillisecondsWithLabel(bottomQuartileMilliseconds)

  const { label: retentionTopQuartileLabel, value: retentionTopQuartileValue } =
    convertMillisecondsWithLabel(topQuartileMilliseconds)

  const bottomQuartileDrillDown = isShowInstancesTable
    ? INVENTORY_INSTANCES_TABLE
    : INVENTORY_VOLUMES_TABLE

  const topQuartileDrillDown = isShowInstancesTable
    ? INVENTORY_INSTANCES_TABLE_DESC
    : INVENTORY_VOLUMES_TABLE_DESC

  if (isLoading) {
    return <RpoRetentionSkeletonLoader />
  }

  return (
    <table className={styles.root}>
      <tbody>
        <tr>
          <td colSpan={2} className={styles.firstTd}>
            <span className={styles.wrapDate}>
              <span className={styles.number}>{averageRetentionValue}</span>{' '}
              <span className={styles.date}>{averageRetentionLabel}</span>
            </span>{' '}
            <span className={styles.text}>Average retention</span>
            <Divider sx={{ my: 1 }} />
          </td>
        </tr>
        <tr>
          <td
            className={styles.columnBorder}
            onClick={onClick(bottomQuartileDrillDown)}
          >
            <div className={styles.wrapDate}>
              <span className={styles.number}>
                {retentionBottomQuartileValue}
              </span>{' '}
              <span className={styles.date}>
                {retentionBottomQuartileLabel}
              </span>
            </div>
            <span className={styles.text}>Bottom quartile</span>
          </td>
          <td onClick={onClick(topQuartileDrillDown)}>
            <div className={styles.wrapDate}>
              <span className={styles.number}>{retentionTopQuartileValue}</span>{' '}
              <span className={styles.date}>{retentionTopQuartileLabel}</span>
            </div>
            <span className={styles.text}>Top quartile</span>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default RetentionCommonStatistic
