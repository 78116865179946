/* eslint-disable import/no-extraneous-dependencies */
import { SystemHelper } from '@lib/helpers'
import { useEffect, useState } from 'react'
import { AwsBackupCoverageResponse } from '@lib/clients/aws-statistics/types'
import { AwsStatsClient } from '@lib/clients/aws-statistics'
import { getLiveAssetsCriteria } from '@store/selectors/list-live-assets.selector'
import { useSelector } from 'react-redux'

const INITIAL_BACKUP_COVERAGE: AwsBackupCoverageResponse = {
  ec2: {
    total: 0,
    covered: 0,
  },
  ebs: {
    total: 0,
    covered: 0,
  },
}

const awsStatsClient = new AwsStatsClient()

export function useFetchAwsBackupCoverage() {
  const { accountIds, regions, includeTags, excludeTags } = useSelector(
    getLiveAssetsCriteria
  )
  const [isLoading, setIsLoading] = useState(false)
  const [backupCoverage, setBackupCoverage] =
    useState<AwsBackupCoverageResponse>(INITIAL_BACKUP_COVERAGE)

  useEffect(() => {
    let isMounted = true
    const fetchBackupCoverageStats = async () => {
      try {
        setIsLoading(true)

        const response = await awsStatsClient.getBackupCoverage({
          regions,
          accountIds,
          includeTags,
          excludeTags,
        })

        if (isMounted) {
          setBackupCoverage(response)
        }
      } catch (error) {
        SystemHelper.sendSentryIfProd(
          'awsStatsClient.getBackupCoverage: failed to fetch Backup coverage count'
        )
      } finally {
        setIsLoading(false)
      }
    }

    fetchBackupCoverageStats()

    return () => {
      isMounted = false
      setBackupCoverage(INITIAL_BACKUP_COVERAGE)
      setIsLoading(false)
    }
  }, [accountIds, regions, includeTags?.tags, excludeTags?.tags])

  return {
    isLoading,
    ...backupCoverage,
  }
}
