/* eslint-disable import/no-extraneous-dependencies */
import { PagePathConstant } from '@lib/constants'
import { useRouter } from 'next/router'
import {
  StatisticRowType,
  useFetchAwsScanCoverage,
} from '../widgets-with-scale/coverage-stats'
import { computeScanCoverageStats } from '@features/DashboardV4/views/utils'

export function useScanCoverageStats() {
  const router = useRouter()
  const { isLoading, ec2, efs, s3, ebs } = useFetchAwsScanCoverage()
  const {
    totalInstances,
    coveredInstances,
    totalVolumes,
    coveredVolumes,
    totalS3Buckets,
    coveredS3Buckets,
    totalFilesystems,
    coveredFilesystems,
    numberOfAssets,
    numberOfAssetsWithScans,
    percentOfAssetsWithScans,
  } = computeScanCoverageStats({
    ec2,
    efs,
    s3,
    ebs,
  })

  const handleDrilldown = (url: string) => {
    router.push(url)
  }

  const scanCoverageStats: Array<StatisticRowType> = [
    {
      assets: totalInstances,
      assetsWithBackups: coveredInstances,
      label: 'EC2 Instances',
      onRowClick: () => handleDrilldown(PagePathConstant.EC2_INVENTORY_TABLE),
    },
    {
      assets: totalVolumes,
      assetsWithBackups: coveredVolumes,
      label: 'EBS Volumes',
      onRowClick: () => handleDrilldown(PagePathConstant.EBS_INVENTORY_TABLE),
    },
    {
      assets: totalS3Buckets,
      assetsWithBackups: coveredS3Buckets,
      label: 'S3 Buckets',
      onRowClick: () =>
        handleDrilldown(PagePathConstant.S3_BUCKET_INVENTORY_TABLE),
    },
    {
      assets: totalFilesystems,
      assetsWithBackups: coveredFilesystems,
      label: 'EFS',
      onRowClick: () => handleDrilldown(PagePathConstant.EFS_INVENTORY_TABLE),
    },
  ]

  return {
    isLoading,
    scanCoverageStats,
    numberOfAssetsWithScans,
    percentOfAssetsWithScans,
    numberOfAssets,
  }
}
