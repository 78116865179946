import React from 'react'
import { ConfigurationVulnerabilities } from '@features/DashboardV4/ConfigurationVulnerabilities'
import { ThreatsWrapper } from '@features/DashboardV4/threats-widgets'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import { ScansAndTests } from '@features/DashboardV4/ScansAndTests'

export function OverviewLeftColumnWithEntropyTrends() {
  return (
    <Grid
      container
      item
      spacing={1}
      padding={2}
      bgcolor="white"
      md={12}
      lg={7.8}
      height="100%"
      marginRight="auto"
    >
      <Grid item xs={12} md={12}>
        <ThreatsWrapper />
      </Grid>
      <Grid item xs={12} marginY={1}>
        <Divider />
      </Grid>
      <Grid item xs={12} md={12}>
        <ScansAndTests />
      </Grid>
      <Grid item xs={12} md={12}>
        <ConfigurationVulnerabilities />
      </Grid>
    </Grid>
  )
}
