import Divider from '@mui/material/Divider'

import { convertMillisecondsWithLabel } from '@features/DashboardV4/widgets-with-scale'
import type { TableElementEvent } from '@features/DashboardV4/widgets-with-scale/shared'
import styles from './common-statistic.module.css'
import {
  INVENTORY_INSTANCES_SHOW_RPO_ASC,
  INVENTORY_INSTANCES_SHOW_RPO_BACKUPS,
  INVENTORY_INSTANCES_SHOW_RPO_DESC,
  INVENTORY_INSTANCES_SHOW_RPO_NO_BACKUPS,
  INVENTORY_VOLUMES_SHOW_RPO_ASC,
  INVENTORY_VOLUMES_SHOW_RPO_DESC,
  INVENTORY_VOLUMES_SHOW_RPO_NO_BACKUPS,
} from '@features/DashboardV4/widgets-with-scale/rpo/utilities'
import { RpoRetentionSkeletonLoader } from '@features/DashboardV4/widgets-with-scale/skeleton'
import { AwsRpoStats } from '@lib/clients/aws-statistics/types'

type RetentionCommonStatisticOwnProps = {
  onClick: (link: string) => (event: TableElementEvent) => void
  instancesNumber: number
  volumesNumber: number
  instancesOutOfScheduleNumber: number
  volumesOutOfScheduleNumber: number
  isLoading: boolean
} & Omit<AwsRpoStats, 'quantiles'>

function computedStyleOutOfSchedulePercent(percentage: number) {
  if (percentage > 0) {
    return {
      color: '#ED3F3F',
    }
  }
  return {}
}

function RetentionCommonStatistic({
  backupsOutOfSchedule,
  lowestRpo,
  highestRpo,
  onClick,
  instancesNumber,
  volumesNumber,
  instancesOutOfScheduleNumber,
  volumesOutOfScheduleNumber,
  isLoading,
}: RetentionCommonStatisticOwnProps) {
  const lowestRpoMilliseconds = lowestRpo || 0
  const highestRpoMilliseconds = highestRpo || 0
  const { label: lowestRpoLabel, value: lowestRpoValue } =
    convertMillisecondsWithLabel(lowestRpoMilliseconds)
  const { label: highestRpoLabel, value: highestRpoValue } =
    convertMillisecondsWithLabel(highestRpoMilliseconds)

  const rpoOutOfScheduleDrillDown =
    instancesOutOfScheduleNumber > 0
      ? INVENTORY_INSTANCES_SHOW_RPO_NO_BACKUPS
      : volumesOutOfScheduleNumber > 0
      ? INVENTORY_VOLUMES_SHOW_RPO_NO_BACKUPS
      : INVENTORY_INSTANCES_SHOW_RPO_BACKUPS

  const rpoAscDrillDown =
    instancesNumber > 0
      ? INVENTORY_INSTANCES_SHOW_RPO_ASC
      : volumesNumber > 0
      ? INVENTORY_VOLUMES_SHOW_RPO_ASC
      : INVENTORY_INSTANCES_SHOW_RPO_ASC

  const rpoDescDrillDown =
    instancesNumber > 0
      ? INVENTORY_INSTANCES_SHOW_RPO_DESC
      : volumesNumber > 0
      ? INVENTORY_VOLUMES_SHOW_RPO_DESC
      : INVENTORY_INSTANCES_SHOW_RPO_DESC

  if (isLoading) {
    return <RpoRetentionSkeletonLoader />
  }

  return (
    <table className={styles.root}>
      <tbody>
        <tr onClick={onClick(rpoOutOfScheduleDrillDown)}>
          <td colSpan={2} className={styles.firstTd}>
            <span className={styles.wrapDate}>
              <span
                style={computedStyleOutOfSchedulePercent(backupsOutOfSchedule)}
                className={styles.number}
              >
                {backupsOutOfSchedule}%
              </span>
            </span>{' '}
            <span className={styles.text}>Backups out of schedule</span>
            <Divider sx={{ my: 1 }} />
          </td>
        </tr>
        <tr>
          <td
            className={styles.columnBorder}
            onClick={onClick(rpoAscDrillDown)}
          >
            <div className={styles.wrapDate}>
              <span className={styles.number}>{lowestRpoValue}</span>{' '}
              <span className={styles.date}>{lowestRpoLabel}</span>
            </div>
            <span className={styles.text}>Lowest RPO</span>
          </td>
          <td onClick={onClick(rpoDescDrillDown)}>
            <div className={styles.wrapDate}>
              <span className={styles.number}>{highestRpoValue}</span>{' '}
              <span className={styles.date}>{highestRpoLabel}</span>
            </div>
            <span className={styles.text}>Highest RPO</span>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default RetentionCommonStatistic
