import React from 'react'
import Grid from '@mui/material/Grid'

import { OverviewLeftColumnWithEntropyTrends } from './left-column'
import { OverviewRightColumnWithEntropyTrends } from './right-column'
import { useRouter } from 'next/router'

export function OverviewWithEntropyTrends() {
  const queryTab = useRouter().query.t as string

  return (
    <Grid container height="100%" key={queryTab}>
      <OverviewLeftColumnWithEntropyTrends />
      <OverviewRightColumnWithEntropyTrends />
    </Grid>
  )
}
