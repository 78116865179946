/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { Divider } from '@mui/material'
import clsx from 'clsx'
import ThreatsWidget from '@features/DashboardV4/threats-widgets/threats-widget'
import { ThreatExposureWidget } from '@features/DashboardV4/threats-widgets'
import { useManagementView } from '../common'
import styles from './threats-wrapper.module.css'

function ThreatsWrapper() {
  const { isRecoveryView } = useManagementView()

  return (
    <div
      className={clsx(styles.wrapper, {
        [String(styles.recovery)]: isRecoveryView,
      })}
      data-testid="threats-wrapper"
    >
      <ThreatsWidget />
      {isRecoveryView && <Divider />}
      <ThreatExposureWidget />
    </div>
  )
}

export default ThreatsWrapper
