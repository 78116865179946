import React from 'react'
import { TableCell, TableContainer, TableRow } from '@mui/material'
import { MUIComponents } from '@features/DashboardV4/common'

export const CustomizedTableContainer: MUIComponents['TableContainer'] = ({
  ...props
}) => (
  <TableContainer
    {...props}
    sx={{
      height: 'calc(100% - 40px)',
      minWidth: 435,
      boxShadow: 'none',
    }}
  />
)

export const CustomizedTableCell: MUIComponents['TableCell'] = ({
  ...props
}) => {
  const { sx, align } = props
  return (
    <TableCell
      {...props}
      align={align}
      sx={{
        ...sx,
        borderBottom: 'none',
        padding: '12px 8px',
      }}
    />
  )
}

export const CustomizedTableRow: MUIComponents['TableRow'] = ({ ...props }) => {
  return (
    <TableRow
      {...props}
      sx={{
        ':not(.MuiTableRow-head)': {
          '&:nth-of-type(odd)': {
            backgroundColor: '#F6F9FC',
          },
          '&:nth-of-type(even)': {
            backgroundColor: '#FFFFFF',
          },
        },
      }}
    />
  )
}
